import React from "react";
import location from "../assets/images/locationIcon.svg";
import phoneIcon from "../assets/images/phoneIcon.svg";
import facebook from "../assets/images/facebookIcon.svg";
import "../assets/css/footer.css";

export const Footer = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const phoneClaro = data.data.phoneNumberClaro
  const numberIconClaro = data.data.phoneNumberClaroIcon
  const url = window.location.pathname;
  return (
    <div className="footer">
      <h2>CONTACTO</h2>
      <ul>
        <li>
          {" "}
          <img src={location} alt="Location" /> Argentina{" "}
        </li>
        { url === "/tiktok" ? (
          <>
            <li>
              {" "}
              <a href="tel:08008001453">
                <img src={phone} alt="Phone" /> 0800 800 1453
              </a>
            </li>
          </>
        ) : url === "/promo" ? (
          <>
            <li>
              {" "}
              <a href={`tel:${phoneClaro}`}>
                <img src={phoneIcon} alt="Phone" /> {numberIconClaro}
              </a>
            </li>
          </>
        ) : (
          <>
            <li>
              {" "}
              <a href={`tel:${phone}`}>
                <img src={phoneIcon} alt="Phone" /> {numberIcon}
              </a>
            </li>
          </>
        )}
        <li>
          {" "}
          <img src={facebook} alt="Facebook" /> Alarmas interactivas
        </li>
      </ul>
    </div>
  );
};
