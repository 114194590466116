import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/images/phoneIcon.svg";

export const Floating = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const phoneClaro = data.data.phoneNumberClaro
  const numberIconClaro = data.data.phoneNumberClaroIcon
  const url = window.location.pathname;
  return (
  <div className="floating">
    { url === "/tiktok" ? (
        <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:08008001453">
            {window.innerWidth < 1001 ? (
              <div>
                <b>0800 800 1453</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>0800 800 1453</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/salta" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:3874800902">
            {window.innerWidth < 1001 ? (
              <div>
                <b>387 480 0902</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>387 480 0902</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/neuquen" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:2994360480">
            {window.innerWidth < 1001 ? (
              <div>
                <b>299 436 0480</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>299 436 0480</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/rosario" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:3415272991">
            {window.innerWidth < 1001 ? (
              <div>
                <b>341 527 2991</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>341 527 2991</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/tucuman" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:3814080313">
            {window.innerWidth < 1001 ? (
              <div>
                <b>381 408 0313</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>381 408 0313</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/sanjuan" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:2644660710">
            {window.innerWidth < 1001 ? (
              <div>
                <b>264 466 0710</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>264 466 0710</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/mendoza" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:2614762330">
            {window.innerWidth < 1001 ? (
              <div>
                <b>261 476 2330</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>261 476 2330</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/cordoba" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:3515685143">
            {window.innerWidth < 1001 ? (
              <div>
                <b>351 568 5143</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>351 568 5143</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/buenosaires" ? (
      <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href="tel:1139884051">
            {window.innerWidth < 1001 ? (
              <div>
                <b>11 3988 4051</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>11 3988 4051</b>
              </div>
            )}
          </a>
        </div>
    ) : url === "/promo" ? (
      <div className="floating-button" type="button">
        <img src={phoneIcon} alt="Phone" className="phoneIcon" />
        <a href={`tel:${phoneClaro}`}>
          {window.innerWidth < 1001 ? (
            <div>
              <b>{numberIconClaro}</b>
            </div>
          ):(
            <div>
              <span>Cotizá ahora </span> <b>{numberIconClaro}</b>
            </div>
          )}
        </a>
      </div>
  ) : (
        <div className="floating-button" type="button">
          <img src={phoneIcon} alt="Phone" className="phoneIcon" />
          <a href={`tel:${phone}`}>
            {window.innerWidth < 1001 ? (
              <div>
                <b>{numberIcon}</b>
              </div>
            ):(
              <div>
                <span>Cotizá ahora </span> <b>{numberIcon}</b>
              </div>
            )}
          </a>
        </div>
    )}
  </div>)
};
